<!-- 카테고리 -->
<template>
  <div>
    <v-app-bar
      color="default"
      internal-activator
      class="fixed-bar-exhibition-event-header"
      bottom
      flat
      height="44"
    >
      <div class="d-flex justify-space-between fill-width align-center">
        <v-icon small @click="onClickPrevIcon">
          $vuetify.icons.prevIcon
        </v-icon>
        <span
          class="one-line-string rixgo-extrabold align-center gray900--text font-g16-24"
          >[당첨자 발표] 9월 회원가입 이벤트</span
        >
        <div class="pb-1" @click="onClickShareIcon">
          <v-icon>$vuetify.icons.shareIcon</v-icon>
        </div>
      </div>
    </v-app-bar>
    <div class="fill-width">
      <v-img
        :src="
          require('../../../assets/images/event/promotionSignUp/promotionSignUp2_1_1.webp')
        "
        height="auto"
      />
      <v-img
        :src="
          require('../../../assets/images/event/promotionSignUp/promotionSignUp2_1_2.webp')
        "
        height="auto"
      />
      <v-img
        :src="
          require('../../../assets/images/event/promotionSignUp/promotionSignUp2_2.webp')
        "
        height="auto"
      />
      <v-img
        :src="
          require('../../../assets/images/event/promotionSignUp/promotionSignUp2_3.webp')
        "
        height="auto"
      />
      <v-img
        :src="
          require('../../../assets/images/event/promotionSignUp/promotionSignUp2_4.webp')
        "
        height="auto"
      >
        <div class="fill-width">
          <v-img
            style="transform: scale(0.63); margin-top: 61.5%"
            :src="
              require('../../../assets/images/event/promotionSignUp/signInButton2_1.webp')
            "
            @click="onClickSignIn()"
          ></v-img>
        </div>
        <div>
          <v-img
            style="transform: scale(0.63); margin-top: 28%"
            :src="
              require('../../../assets/images/event/promotionSignUp/signInButton2_2.webp')
            "
            @click="onClickSubmit()"
          ></v-img>
        </div>
      </v-img>
      <v-img
        :src="
          require('../../../assets/images/event/promotionSignUp/promotionSignUp2_5.webp')
        "
        height="auto"
      />
    </div>
    <Sharing
      :content="shareProps.description"
      :shareItem="shareItem"
      :show="share"
      :url="shareProps.copyUrl"
      :store="shareProps.title"
      sheetTitle=""
      @onClose="share = false"
    />
    <DialogAlertView
      :dialogAlert="dialogAlert"
      :alertText="alertTextHeader"
      :alertDescription="alertTextDescription"
      @alertConfirmClick="alertConfirmClick"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import Sharing from '../../common/dialogs/Sharing.vue'
import DialogAlertView from '../login/DialogAlert.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default Vue.extend({
  name: 'PromotionSignUp2View',
  components: {
    Sharing,
    DialogAlertView,
  },
  props: {
    scrollLocation: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      alertTextHeader: '',
      alertTextDescription: '',
      dialogAlert: false,
      share: false,
      shareItem: {
        title: '[당첨자 발표] 9월 회원가입 이벤트',
        description: '',
        imageUrl: 'https://admin-img.cellook.kr/event/september-7c.png',
        url: window.location.origin + '/promotionSignUp2',
      },
      shareProps: {
        title: '[당첨자 발표] 9월 회원가입 이벤트',
        description: '',
        copyUrl: window.location.origin + '/promotionSignUp2',
      },
    }
  },
  async created() {
    this.$getAppHtml.addEventListener('scroll', this.handleScroll)
    //shopId에 맞게 JSON 데이터 바인딩
  },
  destroyed() {
    this.$getAppHtml.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.setScrollLocation()
  },
  computed: {
    ...mapState('DeviceStore', ['deviceInfo']),
  },
  methods: {
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('ExhibitionStore', ['fetchEventExhibitionScrollLocation']),
    onClickImage(e) {
      console.log(e.offsetX, e.offsetY)
    },
    setScrollLocation() {
      setTimeout(() => {
        this.$getAppHtml.scrollTo({
          top: this.scrollLocation,
          behavior: 'auto',
        })
      }, 1000)
    },
    handleScroll() {
      this.fetchEventExhibitionScrollLocation(this.$getAppHtml.scrollTop)
    },
    onClickPrevIcon() {
      this.$router.push('/')
    },
    onClickShareIcon() {
      this.share = true
    },
    onClickSignIn() {
      if (!this.isLogined) {
        // 로그인창 오픈
        this.fetchLoginDialog({ yn: true })
        return
      } else {
        this.alertTextHeader =
          '이미 셀룩의 회원이시네요. 바로 STEP2로 넘어가시면 됩니다.'
        this.dialogAlert = true
      }
    },
    alertConfirmClick() {
      this.dialogAlert = false
    },
    onClickSubmit() {
      this.alertTextHeader = '종료된 이벤트입니다.'
      this.dialogAlert = true
    },
    setExhibitionEventScrollLocation() {
      localStorage.setItem(
        'exhibitionEventScrollLocation',
        JSON.stringify(this.$getAppHtml.scrollTop)
      )
    },
  },
})
</script>
<style scoped>
.fixed-bar-exhibition-event-header {
  position: sticky;
  top: 0em;
  z-index: 102;
}
.bar {
  border-bottom: 1px solid #eaebed;
}
</style>
