
import Vue from 'vue'
import PromotionSignUp2View from '../../views/event/PromotionSignUp2.vue'
export default Vue.extend({
  name: 'PromotionSignUp2',
  components: { PromotionSignUp2View },
  data() {
    return {
      scrollLocation: 0,
    }
  },
  created() {
    if (localStorage.getItem('exhibitionEventScrollLocation')) {
      this.scrollLocation = Number(
        localStorage.getItem('exhibitionEventScrollLocation')
      )
    }
    localStorage.removeItem('exhibitionEventScrollLocation')
  },
})
